<template>
  <v-dialog v-model="dialog" max-width="800" hide-overlay scrollable>
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Schedule supervisors</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-row no-gutters>
          <table class="table">
            <thead>
            <tr>
            <th>Supervisor name</th>
            <th>Options</th>
          </tr>
          </thead>
            <tbody>
            <tr v-if="examDayDetail && examDayDetail.supervisor_ids.length > 0" v-for="(supervisor, index) in examDayDetail.supervisor_list" :key="index">
              <td>{{supervisor.full_name}}</td>
              <td>  <a href="#!" @click="deleteSupervisor(supervisor.id)" class="ml-2">
                Remove
              </a></td>
            </tr>
            <tr v-if="examDayDetail && !examDayDetail.supervisor_ids">
              <td colspan="4">
                Supervisors not available
              </td>
            </tr>
          </tbody>
          </table>
        </v-row>
        <v-divider></v-divider>
        <v-container>
          <v-row>
            <v-col md="5" v-if="checkSupervisor">
              <label for="#">Check supervisor availability</label>
              <v-autocomplete
                  label="Search supervisor by name or email"
                  outlined
                  dense
                  v-model="supervisorAvailabilityDetail" :error="$v.supervisorAvailabilityDetail.$error"   :items="available_supervisors"  :search-input.sync="availableSupervisorSearch"
                  cache-items   item-text="full_name" return-object
                  class="ml-2"
              ></v-autocomplete>
              <span class="text-danger" v-if="$v.supervisorAvailabilityDetail.$error">This information is required</span>
            </v-col>
            <v-col  md="5" v-if="checkSupervisor">
              <label for="#">Date</label>
              <span class="d-flex justify-content-between">
                        <v-menu
                            ref="menu"
                            no-title
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="Select Date"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense v-model="exam_date"
                                outlined
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                no-title
                                v-model="exam_date"
                            ></v-date-picker>
                        </v-menu>
                        <v-btn
                            color="black lighten-3"
                            class="text-white ml-2 mt-1"
                            @click="checkSupervisorAvailability"
                        >
                            Check
                        </v-btn>
                    </span>
            </v-col>
            <v-divider></v-divider>
            <v-col :md="checkSupervisor?2:12" class="text-right">
              <a @click="checkSupervisorAvailabilityToggle" title="Check Supervisor Availability">
                <i :class="!checkSupervisor ?`fas fa-search`:`fas fa-close`" style="height: 60px"></i>
              </a>
            </v-col>

            <v-alert v-if="supervisorCheckError==2"
                     class="mt-0 ml-4 text-center"
                     :icon="true"
                     dense
                     outlined
                     type="success"
            >
              <b>Great, {{supervisorAvailabilityDetail.full_name}} is available for {{exam_date}}</b>
            </v-alert>

            <v-alert v-if="supervisorCheckError==1"
                     class="mt-0 ml-4 text-center"
                     :icon="true"
                     dense
                     outlined
                     type="warning"
            >
              <b>Sorry, {{supervisorAvailabilityDetail.full_name}} is not available for {{exam_date}}</b>
            </v-alert>


          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <h5>Search supervisor</h5>

            </v-col>
            <v-col cols="12" md="5">

              <v-autocomplete @change="changeSupervisors" v-model="supervisorDetail" label="Search supervisor by name or email" outlined dense :items="supervisors"  :search-input.sync="supervisorSearch"
                              cache-items   item-text="full_name" return-object >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="supervisor_lists.length > 0" v-for="(supervisor,index) in supervisor_lists">
            <v-col md="7">
              <div style="display: inline-block">
                <span class="font-weight-bold h6">{{supervisor.full_name}}</span>  <span class="font-weight-medium h6">( {{supervisor.email}} )</span>
              </div>
            </v-col>
            <v-col md="4">
              <v-btn @click="removeSupervisor(index)"
                  class="mx-2 mb-4"
                  fab
                  dark
                  small
                  color="pink"
              >
                <v-icon dark>
                  mdi-delete
                </v-icon>
              </v-btn>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            x-large
            @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn v-if="supervisor_lists.length>0"
               dark
               x-large
               @click="updateSupervisors()"
               :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SupervisorService from "@/services/user/supervisor/SupervisorService";
import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";
import { required, requiredIf } from "vuelidate/lib/validators";


const examDay=new ExamDayService();
const supervisor=new SupervisorService();

export default {
  validations: {
    supervisorAvailabilityDetail:{required},
  },
  props:['exam_day'],
  data(){
    return{
      dialog:false,
      supervisorDetail:{},
      supervisors:[],
      available_supervisors:[],
      supervisorSearch:'',
      supervisor_lists:[],
      loading:false,
      examDayDetail:{},
      exam_day_info:{
        id:'',
        supervisor_ids:'',
      },
      checkSupervisor:false,
      availableSupervisorSearch:'',
      supervisorCheckError:0,
      exam_date:'',
      supervisorAvailabilityDetail:{},
      checkSupervisorDate:false,
      saveSupervisor:false,
    }
  },
  methods:{
    closeDialog(){
      this.dialog=false;
      this.resetData();
    },
    openDialog(examId){
      this.dialog=true;
      this.exam_day.id=examId;
      this.findExamDayDetail(examId);
    },
    changeSupervisors(){
      let obj = null;
      if(this.supervisor_lists.length > 0){
        let index=this.supervisor_lists.findIndex(val =>val.id==this.supervisorDetail.id);
        obj = this.supervisor_lists[index];
      }
      if(obj == undefined || obj == null){
        let data={'id':this.supervisorDetail.id,'full_name':this.supervisorDetail.full_name,'email':this.supervisorDetail.email}
        this.supervisor_lists.push(data);
      }else{
        this.$snotify.error('Supervisor has been Already Added');
      }
      this.supervisorDetail={};
      this.supervisorSearch='';
    },
    removeSupervisor(index){
      this.supervisor_lists.splice(index,1);
    },
    buildData(){
      if(this.supervisor_lists.length>0){
        let supervisor_ids=[];

        this.supervisor_lists.forEach((value, index) => {
          supervisor_ids.push(value.id);
        });
        this.exam_day_info.supervisor_ids=supervisor_ids;
      }
    },
    updateSupervisors(){
      this.buildData();
      examDay
          .assignSupervisor(this.exam_day.id,this.exam_day_info)
          .then((response) => {
            this.$emit('refresh')
            this.$snotify.success('Supervisor(s) saved successfully.');
            this.dialog = false;
            this.supervisor_lists = [];
            this.resetData();
          })
          .catch(err => {
            this.$snotify.error('Problem Occurred');
            this.dialog = false;

          })
          .finally(() => (this.loading = false))
    },
    findExamDayDetail(examDayId){
      examDay
          .show(examDayId)
          .then((response) => {
            this.examDayDetail=response.data.examDay;
            this.exam_date=this.examDayDetail.exam_date;

          })
          .catch(err => {

          })
          .finally(() => (this.loading = false))
    },
    deleteSupervisor(supervisorId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let data = {
              exam_day_id:this.examDayDetail.id,
              supervisor_id:supervisorId,
            };
            examDay
                .deleteSupervisor(data)
                .then((response) => {
                  this.$emit('refresh')
                  this.$snotify.success('Supervisor deleted');
                  this.dialog = false;
                  this.supervisor_lists = [];

                })
                .catch(err => {
                  this.$snotify.error('Problem Occurred');
                  this.dialog = false;

                })
                .finally(() => (this.loading = false))
          }
        },
      });
    },
    checkSupervisorAvailabilityToggle()
    {
      this.checkSupervisor = !this.checkSupervisor;
    },
    checkSupervisorAvailability() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        let data = {
          supervisor_id: this.supervisorAvailabilityDetail.id,
          exam_date: this.exam_date,
          exam_day_id: this.examDayDetail.id,
        }
        examDay
            .checkSupervisorAvailability(data)
            .then((response) => {

              if (response.data.status == 'ERROR') {
                this.supervisorCheckError = 2;
              }
              if (response.data.status == 'OK') {
                this.supervisorCheckError = 1;
              }

            })
            .catch(err => {


            })
      }
    },
    resetData(){
      this.supervisor_lists=[];
      this.available_supervisors=[];
      this.supervisors=[];
      this.checkSupervisor=false;
      this.supervisorCheckError=0;
      this.supervisorDetail={};
      this.supervisorSearch='';
      this.availableSupervisorSearch='';
    }
  },
  mounted() {

  },
  watch: {
    supervisorSearch(val) {
      let data = {
        info: val,
        score_id:this.exam_day.score_id,
      }
      supervisor
          .search(data)
          .then((response) => {
            this.supervisors = response.data.data;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    },
    availableSupervisorSearch(val)
    {
      let data={
         info:val,
         score_id:this.exam_day.score_id,
         date:'',
      }
      supervisor
          .search(data)
          .then((response) => {
            this.available_supervisors=response.data.data;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    }
  }
}
</script>