<template>
  <v-row justify="center">
    <v-dialog
        persistent
        scrollable
        v-model="dialog"
        max-width="400"
    >
      <v-card>
        <v-toolbar flat>
          <v-card-title>
            <span>Add video url</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog=false">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-row class="mt-1">

            <v-col  cols="12" >
              <v-text-field
                  :label="`Video url for ${candidate_timetable.full_name}`"
                  outlined
                  class="form-select form-select-solid select2-hidden-accessible"
                  dense
                  type="url"
                  item-text="title"
                  v-model="candidate_timetable.video_exam_url"
                  :error="$v.candidate_timetable.video_exam_url.$error"
              > </v-text-field>
            </v-col>


          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              x-large
              text
              data-kt-menu-dismiss="true"
              @click="dialog=false"
          >
            Cancel
          </v-btn>

          <v-btn
              x-large
              dark
              data-kt-menu-dismiss="true"
              @click="addVideoUrl"
          >
            Add url
          </v-btn>



        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import {required,numeric, requiredIf,maxValue} from "vuelidate/lib/validators";
import CandidateService from '@/services/candidate/CandidateService';
const candidate = new CandidateService();

export default {

  validations:{
    candidate_timetable:{
      video_exam_url:{required},
    }},
  components:{
  },
  props:['candidate_id'],
  data(){
    return {
      dialog:false,
      candidate_timetable:{
        video_exam_url:''
      },
    }
  },
  methods:{
    openDialog(candidate)
    {
      this.$v.$reset();
      this.dialog = true;
      this.candidate_timetable = candidate;

    },

    closeDialog()
    {
      this.dialog = false;
    },

    addVideoUrl()
    {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.$emit('refresh',this.candidate_timetable);
        this.dialog = false;
        this.$snotify.success('Video url added');
      }
    }
  },
  mounted() {

  },
};
</script>