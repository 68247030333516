<template>
  <div>

    <v-row class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start" no-gutters>
      <v-col cols="2">
            <v-text-field
                label="Candidate name"
                outlined
                dense
                class="mr-2"
                v-model="search.candidate_name"
                @input="search.candidate_name = $event !== null ? $event : ''"
                v-on:keyup.enter="getTimetableNotAssignedCandidates()"
                clearable
            ></v-text-field>
      </v-col>

      <v-col cols="2">

            <v-text-field
                label="Enrolment key"
                outlined
                dense
                class="mr-2"
                v-model="search.exam_key"
                @input="search.exam_key = $event !== null ? $event : ''"
                v-on:keyup.enter="getTimetableNotAssignedCandidates()"
                clearable
            ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
            label="Enroller name"
            outlined
            dense
            class="mr-2"
            v-model="search.account_holder_name"
            @input="search.account_holder_name = $event !== null ? $event : ''"
            v-on:keyup.enter="getTimetableNotAssignedCandidates()"
            clearable
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
            label="Enroller email"
            outlined
            dense
            class="mr-2"
            v-model="search.account_holder_email"
            @input="search.account_holder_email = $event !== null ? $event : ''"
            v-on:keyup.enter="getTimetableNotAssignedCandidates()"
            clearable
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-select
            label="Scheduling request"
            outlined
            dense
            :items="scheduling_requests"
            v-model="search.schedule_request_id"
            class="mr-2"
            item-text="name"
            item-value="id"
            @input="search.schedule_request_id = $event !== null ? $event : ''"
            v-on:keyup.enter="getTimetableNotAssignedCandidates()"
            clearable
        ></v-select>
      </v-col>

      <v-col cols="2">
        <v-text-field
          outlined
          dense
          v-model="search.scheduling_remarks"
          label="Scheduling remarks"
          clearable
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
               label="Teacher name"
               outlined
               dense
               v-model="search.teacher_name"
               @input="search.teacher_name = $event !== null ? $event : ''"
               v-on:keyup.enter="getTimetableNotAssignedCandidates()"
               clearable
               class="mr-2"
           ></v-text-field>
      </v-col>

      <v-col cols="2" >
        <v-select
            label="Instrument "
            v-model="search.instrument_id"
            item-text="name"
            v-on:keyup.enter="getTimetableNotAssignedCandidates()"
            item-value="id"
            class="mr-2"
            :items="instruments"
            outlined clearable
            dense
        ></v-select>
      </v-col>
      <v-col cols="2" >
        <v-select
            label="Syllabus "
            v-model="search.syllabus_id"
            item-text="name" v-on:keyup.enter="getTimetableNotAssignedCandidates()"
            item-value="id"
            class="mr-2"
            :items="syllabuses"
            outlined clearable
            :loading="isLoading"
            dense
        ></v-select>
      </v-col>


      <v-col cols="2">
        <v-select
            label="Grades"
            outlined
            dense
            :items="grades"
            v-model="search.grade_id"
            item-text="name"
            item-value="id"
            class="mr-2"
            @input="search.grade_id = $event !== null ? $event : ''"
            v-on:keyup.enter="getTimetableNotAssignedCandidates()"
            clearable
        ></v-select>
      </v-col>

      <v-col cols="2">

        <v-text-field
            label="Exam name"
            outlined
            dense
            v-model="search.exam_name"
            @input="search.exam_name = $event !== null ? $event : ''"
            v-on:keyup.enter="getTimetableNotAssignedCandidates()"
            clearable
            class="mr-2"
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
            label="Group identifier"
            outlined
            dense
            v-model="search.group_identifier"
            @input="search.group_identifier = $event !== null ? $event : ''"
            v-on:keyup.enter="getTimetableNotAssignedCandidates()"
            clearable
            class="mr-2"
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-select
          outlined
          dense
          :items="sortByValues"
          item-text="name"
          item-value="value"
          label="Sort by"
          clearable
          v-model="search.sort_by"
        ></v-select>
      </v-col>

      <v-col cols="8"></v-col>

      <v-col cols="2" class="text-right">
        <v-btn class="btn btn-block text-white btn-primary ml-0" @click="getTimetableNotAssignedCandidates" :loading="isLoading" dense>
          Filter
        </v-btn>
      </v-col>

    </v-row>
    <v-row no-gutters ref="candidate-infos">
      <v-col v-for="(candidateInfo, index) in candidate_infos"  v-bind:key="candidateInfo.id" class="col-md-3 scheduling_info_box font-size-sm" >
        <div
            class="ml-2"
        >
          <v-col class="text-right mt-0"> <a href="#" @click.prevent="removeCandidateFromInfo(index)"><i class="fa fa-close"></i></a></v-col>
            <div class="form-block">
              <v-row no-gutters  >
                <v-col cols="12">
                  <b>Candidate name:</b> {{candidateInfo.full_name}}<br>
                  <b>Enrolment key:</b> <a href="#" @click="getCandidateSummary(candidateInfo.exam_key)"> {{candidateInfo.exam_key}} </a><br>
                  <b>Date of birth:</b> {{candidateInfo.date_of_birth}}<br>
                </v-col>

                <v-col cols="12">
                  <hr>
                  <b>Enroller info</b><br/>
                  <b>Name:</b> {{candidateInfo.owner.full_name}}<br>
                  <b>Email:</b> {{candidateInfo.owner.email}}<br>
                </v-col>

                <v-col cols="12" v-if="candidateInfo.has_teacher_info=='1'">
                  <hr>
                  <b>Teacher info</b><br/>
                  <b>Name:</b> {{candidateInfo.teacher_full_name}}<br>
                  <b>Email:</b> {{candidateInfo.teacher_email}}<br>
                </v-col>

                <v-col cols="12" v-if="candidateInfo.has_parent_info=='1'">
                  <hr>
                  <b>Parent/guardian info</b><br/>
                  <b>Name:</b> {{candidateInfo.parent_full_name}}<br>
                  <b>Email:</b> {{candidateInfo.parent_email}}<br><br>
                </v-col>
              </v-row>
            </div>
        </div>
      </v-col>
    </v-row>

  <v-row no-gutters id="scheduledDraggable">
    <v-col cols="6" class="p-2">
      <v-card class="mt-4">
        <v-card-title class="p-2" style="background: #e4e6ef; position: relative" >
          Candidates not scheduled <small class="ml-3"> </small> <small class="push-right" style="position: absolute; right: 15px"><a href="#!"><i class="fa fa-refresh" @click="getTimetableNotAssignedCandidates" style="font-size: 13px; margin-right: 5px" alt="Refresh"></i></a>  Showing {{showing_candidates}} of {{total_candidates}} </small>
        </v-card-title>
      <draggable class="list-group p-2 overflow-auto" :list="timetable_not_assigned_candidates" group="people" @change="changeToUnassigned" :style="`min-height:${minHeight}; max-height: 900px; background-color: #f3f6f9`">
        <div v-if="timetable_not_assigned_candidates.length>0"
            class="list-group-item scheduling-items mb-2 "
            v-for="(candidate, index) in timetable_not_assigned_candidates"
            :key="candidate.id"
        >
          <div class="mb-2" v-if="candidate.is_break==0 &&candidate.is_empty_slot==0 && candidate.is_candidate_removed_slot==0"  dense>
            <v-row class="d-flex justify-content-between" no-gutters>
              <v-col cols="6">
                <span class="badge border border-black text-black p-1 mb-1 font-size-md" style="background: #ffffff" >
                  <b>{{ candidate.full_name }}</b>
                </span>
              </v-col>
              <v-col cols="6" class="text-right">
                <span class="badge badge-primary" :style="candidate.account_holder_email" >
                  {{candidate.account_holder_email}}
                </span>
              </v-col>
            </v-row>
            <v-row class="mt-2" no-gutters>
              <v-col cols="6">
                <v-btn
                    x-small
                    title="Candidate detail"
                    class="schedule_btns_group"
                    @click="viewCandidateInformation(candidate.exam_key)"
                >
                  <i class="fa fa-info"></i>
                </v-btn>
                <v-btn
                    :x-small="true"
                    :title="candidate.grade_name"
                    class="schedule_btns_group ml-2"
                >
                  {{candidate.grade_short_name}}
                </v-btn>
                <v-btn
                    :x-small="true"
                    title="Exam duration"
                    class="schedule_btns_group ml-2"
                >
                  <i class="fa fa-clock" style="height: 15px"></i> {{candidate.exam_duration}}
                </v-btn>
                <v-btn
                    v-if="!candidate.is_payment_complete"
                    :x-small="true"
                    title="Pending payment"
                    class="schedule_btns_group ml-2"
                >
                  <i class="fas fa-money-bill"></i>
                </v-btn>
                <v-btn
                    v-if="candidate.has_candidate_attachments"
                    :x-small="true"
                    title="Candidate attachments"
                    class="schedule_btns_group ml-2"
                    @click="viewCandidateSummary(candidate.exam_key)"
                >
                  <i class="flaticon-attachment" style="height: 15px"></i>
                </v-btn>

                <v-btn
                    :x-small="true"
                    :title="candidate.is_grouped=='1'?`Group`:`Individual`"
                    class="schedule_btns_group ml-2"
                    @click="changeCandidateToIndividualOrGroup(index,'unassigned')"
                >
                  <i :class="candidate.is_grouped=='1'?`fa fa-users`:`fa fa-user`"></i>
                  <span v-if="candidate.is_grouped && candidate.class_exam_identifier!=null" class="badge badge-info"> <b>{{candidate.class_exam_identifier}}</b></span>
                </v-btn>

                <v-btn
                  v-if="candidate.require_recorded_accompaniment"
                  :x-small="true"
                  title="Recorded accompaniment"
                  class="schedule_btns_group ml-2"
                >
                  <i class="fa fa-compact-disc" ></i>
                </v-btn>

                <v-btn
                    :x-small="true"
                    title="Move to right"
                    class="schedule_btns_group ml-2"
                    @click="moveToRight(index,candidate)"
                >
                  <i class="fas fa-arrow-right"></i>
                </v-btn>

              </v-col>

              <v-col cols="12" class="mt-1 p-2 font-size-sm border rounded mt-2"  :style="candidate.scheduling_request? `background-color: ${candidate.scheduling_request_color};`:'background-color: #e5e9ed;'">
                <span v-if="candidate.teacher_name">
                  <b>Teacher name: </b>
                  {{candidate.teacher_name}} <br/></span>

                <span v-if="candidate.syllabus_name">
                  <b>Exam name: </b>
                  {{candidate.syllabus_name}}  <br/></span>

                <span v-if="candidate.exam_type">
                  <b>Exam type: </b>
                  {{candidate.exam_type}}
                     <span v-if="candidate.exam_option">
                 |  <b>Exam option: </b>
                  {{candidate.exam_option}}</span>
                    <br/></span>

                <span v-if="candidate.scheduling_request">
                  <b>Scheduling request: </b>
                  {{candidate.scheduling_request}}<br/></span>

                <span v-if="candidate.scheduling_remarks">
                  <b>Scheduling remarks: </b>
                  {{candidate.scheduling_remarks}}<br/></span>
                <span v-if="candidate.unsuitable_examiners">
                  <b>Unsuitable examiners: </b>
                  {{candidate.unsuitable_examiners}}<br/></span>
                <span v-if="candidate.unsuitable_examiner_comments">
                  <b>Unsuitable examiners note: </b>
                  {{candidate.unsuitable_examiner_comments}}<br/></span>
                <span v-if="candidate.special_assistance_required">
                  <b>Special assistance: </b>
                  {{candidate.special_assistance_required? 'Yes': 'No'}}<br/></span>
                <span v-if="candidate.special_assistance_remarks_by_user">
                  <b>Special assistance note: </b>
                  {{candidate.special_assistance_remarks_by_user}}<br/></span>
              </v-col>
            </v-row>
          </div>
        </div>

        <div v-if="timetable_not_assigned_candidates.length<1">
          <v-row no-gutters class="mt-5 ml-5 mr-5">
            <v-alert

                border="bottom"
                colored-border
                type="warning"
                elevation="5"
            >
             All the candidates are either scheduled or there are no more candidates suitable for this Schedule.

            </v-alert>
          </v-row>
        </div>
      </draggable>
      </v-card>
    </v-col>

    <v-col cols="6" class="p-2" >
      <v-card class="mt-4">
        <v-card-title class="p-2" style="background:rgb(228 230 239);" >
          Candidates scheduled
          <v-row no-gutters>
            <v-col cols="12" class="text-right">
              <v-btn
                  medium
                  title="Add break"
                  class="btn text-white ml-2 btn btn-purple"
                  @click="showAddBreakForm=!showAddBreakForm"
              >
                <i class="fa fa-clock"></i>
                Add break
              </v-btn>
              <v-btn
                  medium
                  title="Save"
                  @click="saveScheduledCandidates"
                  class="btn text-white ml-2 btn btn-warning"
              >
                <i class="fa fa-save"></i>
                Save
              </v-btn>
              <v-btn
                  medium
                  title=" Save & confirm"
                  class="btn text-white ml-2 btn btn-success"
                  @click="saveAndConfirm"
              >
                <i class="fa fa-check-circle"></i>
                Save & confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>


        <draggable class="list-group p-2 overflow-auto" :list="timetable_assigned_candidates"  group="people" @change="changeToAssigned" :style="`min-height:${minHeight}; max-height: auto; background: #f3f6f9;`" ghost-class="ghost">
          <div v-if="timetable_assigned_candidates.length>0" class="list-group-item scheduling-items mb-2" v-for="(candidate, index) in timetable_assigned_candidates" :key="candidate.id">
             <div v-if="candidate.is_break=='0' && candidate.is_empty_slot=='0' && candidate.is_candidate_removed_slot==0">
               <div>
               <v-row class="d-flex justify-content-between mb-1" no-gutters>
                 <v-col cols="6">
                    <span class="badge border border-black text-black p-1 mb-1 font-size-md" style="background: #ffffff" >
                    <b>{{ candidate.full_name }}</b>
                  </span>
                 </v-col>
                 <v-col cols="6" class="text-right">
                    <span class="badge badge-primary font-weight-bold" :style="candidate.account_holder_email && candidate.account_holder_email.length>30?`height: 2em; font-size:0.7rem `:`height: 2em;`">
                      {{candidate.account_holder_email}}
                    </span>
                 </v-col>
               </v-row>
               <v-row class="mt-1" no-gutters>
                 <v-col cols="9">
                   <div>

                     <v-btn
                         :x-small="true"
                         title="Move to left"
                         class="schedule_btns_group ml-2"
                         @click="moveToLeft(index,candidate)"
                     >
                       <i class="fas fa-arrow-left"></i>
                     </v-btn>

                     <v-btn
                         :x-small="true"
                         title="Candidate details"
                         class="schedule_btns_group ml-2"
                         @click="viewCandidateInformation(candidate.exam_key)"
                     >
                       <i class="fa fa-info"></i>
                     </v-btn>
                     <v-btn
                         :x-small="true"
                         v-if="candidate.special_assistance_required"
                         :title="candidate.special_assistance_remarks_by_user"
                         class="schedule_btns_group ml-2"
                     >
                       <i class="fa fa-wheelchair"></i>
                     </v-btn>
                     <v-btn
                         :x-small="true"
                         v-if="candidate.drum_selection && candidate.hand_type"
                         :title="candidate.hand_type"
                         class="schedule_btns_group ml-2"
                     >
                       <i class="fa fa-drum"></i> <span v-if="candidate.hand_type=='right'">R</span><span v-if="candidate.hand_type=='left'">L</span>
                     </v-btn>
                     <v-btn
                         :x-small="true"
                         :title="candidate.grade_name"
                         class="schedule_btns_group ml-2"
                     >
                       {{candidate.grade_short_name}}
                     </v-btn>
                     <v-btn
                         :x-small="true"
                         title="Exam duration"
                         class="schedule_btns_group ml-2"
                     >
                       <i class="fa fa-clock" ></i> {{candidate.exam_duration}}
                     </v-btn>

                     <v-btn v-if="exam_day.type=='manual'"
                            :x-small="true"
                            title="Set time duration"
                            class="schedule_btns_group ml-2"
                            @click="setManualDurationModal(index)"
                     >
                       <i class="fa fa-stopwatch"></i>
                     </v-btn>

                     <v-btn
                         v-if="candidate.has_candidate_attachments"
                         :x-small="true"
                         title="Candidate attachments"
                         class="schedule_btns_group ml-2"
                         @click="viewCandidateSummary(candidate.exam_key)"
                     >
                       <i class="flaticon-attachment"></i>
                     </v-btn>

                     <v-btn v-if="exam_day.participation_way=='video'"
                            :x-small="true"
                            title="Set video URL"
                            class="schedule_btns_group ml-2"
                            @click="setVideoUrlModal(index)"
                     >
                       <i class="fa fa-video"></i>
                     </v-btn>

                     <v-btn
                            v-if="!candidate.is_payment_complete"
                            :x-small="true"
                            title="Pending payment"
                            class="schedule_btns_group ml-2"
                     >
                       <i class="fas fa-money-bill"></i>
                     </v-btn>

                     <v-btn
                         :x-small="true"
                         v-if="candidate.is_saved"

                         class="schedule_btns_group ml-2"
                         @click="changeCandidateToIndividualOrGroup(index,'assigned')"
                     >
                       <i :class="candidate.is_grouped=='1'?`fa fa-users`:`fa fa-user`"></i>
                       <span v-if="candidate.is_grouped && candidate.class_exam_identifier!=null" class="badge badge-info"> <b>{{candidate.class_exam_identifier}}</b></span>
                     </v-btn>

                     <v-btn
                         :x-small="true"
                         v-if="candidate.is_saved"
                         :title="candidate.is_locked=='1'?`Locked`:`Unlocked`"
                         class="schedule_btns_group ml-2"
                         @click="changeCandidateToLockedOrUnlocked(index)"
                     >
                       <i :class="candidate.is_locked=='1'?`fa fa-lock`:`fa fa-unlock`" ></i>
                     </v-btn>

                     <v-btn
                         v-if="candidate.is_saved=='1'"
                         @click="removeCandidateAndCreateEmptySlot(index)"
                         :x-small="true"
                         title="Remove candidate and save  empty slot"
                         class="schedule_btns_group ml-2"
                     >
                       <i class="fa fa-trash" ></i>
                     </v-btn>

                     <v-btn
                         v-if="candidate.require_recorded_accompaniment"
                         :x-small="true"
                         title="Recorded accompaniment"
                         class="schedule_btns_group ml-2"
                     >
                       <i class="fa fa-compact-disc" ></i>
                     </v-btn>
                     



                   </div>


                 </v-col>
                 <v-col cols="3" class="text-right block mt-2">
                   <span v-if="candidate.is_manual_time_applied" class="badge text-white font-weight-bold badge-info mr-2"> <i class="fa-solid fa-stopwatch" title="Manually Adjusted Time" style="height: 10px"></i></span>
                   <span class="badge text-white font-weight-bold" style="background-color: #D78B00"> {{candidate.exam_start_time}} to  {{candidate.exam_end_time}}</span>
                 </v-col>

                 <v-col cols="12" class="mt-1 p-2 font-size-sm border rounded mt-2" :style="candidate.scheduling_request? `background-color: ${candidate.scheduling_request_color};`:'background-color: #e5e9ed;'">
                <span v-if="candidate.teacher_full_name">
                  <b>Teacher name: </b>
                  {{candidate.teacher_full_name}} <br/></span>

                   <span v-if="candidate.syllabus_name">
                  <b>Exam name: </b>
                  {{candidate.syllabus_name}}  <span v-if="candidate.exam_type">
                  | <b>Exam type: </b>
                  {{candidate.exam_type}} </span>
                        <span v-if="candidate.exam_option">
                  | <b>Exam option: </b>
                  {{candidate.exam_option}}  </span>
                       <br/></span>
                   <span v-if="candidate.scheduling_request">
                  <b>Scheduling request: </b>
                  {{candidate.scheduling_request}}<br/></span>

                   <span v-if="candidate.scheduling_remarks">
                  <b>Scheduling remarks: </b>
                  {{candidate.scheduling_remarks}}<br/></span>
                   <span v-if="candidate.hand_type">
                     <b>L/R handed: </b>
                   {{candidate.hand_type}}<br/></span>
                   <span v-if="candidate.guitar_selection">
                     <b>Guitar selection: </b>
                   {{candidate.guitar_selection}}<br/></span>
                   <span v-if="candidate.drum_selection">
                     <b>Guitar selection: </b>
                   {{candidate.drum_selection}}<br/></span>
                   <span v-if="candidate.can_consider_electric_drum_kit">
                     <b>Consider electric drum kit: </b>
                   {{candidate.can_consider_electric_drum_kit}}<br/></span>
                   <span v-if="candidate.unsuitable_examiners">
                  <b>Unsuitable examiners: </b>
                  {{candidate.unsuitable_examiners}}<br/></span>
                   <span v-if="candidate.unsuitable_examiner_comments">
                  <b>Unsuitable examiners note: </b>
                  {{candidate.unsuitable_examiner_comments}}<br/></span>
                   <span v-if="candidate.special_assistance_required">
                  <b>Special assistance: </b>
                  {{candidate.special_assistance_required ? 'Yes' : 'No'}}<br/></span>
                   <span v-if="candidate.special_assistance_remarks_by_user">
                  <b>Special assistance note: </b>
                  {{candidate.special_assistance_remarks_by_user}}<br/></span>
                 </v-col>
               </v-row>

               </div>
             </div>

             <div  v-if="candidate.is_break=='1'">
                <v-alert :style="`background:${candidate.background_color} !important`" height="40"  text dense rounded>
                <v-row class="d-flex justify-content-between" no-gutters>
                  <v-col cols="6">
                    <span  class="badge text-white " >
                      <b>{{ candidate.break_title }}</b>
                    </span>
                    <v-btn
                        :x-small="true"
                        title="Exam duration"
                        class="schedule_btns_group ml-2"
                    >
                      <i class="fa fa-clock" ></i> {{candidate.exam_duration}}
                    </v-btn>

                    <v-btn
                        :x-small="true"
                        title="Delete break"
                        class="btn text-white ml-2 btn-standard"
                        @click="deleteBreak(index)"
                    >
                      <i class="fa fa-close" style="height: 15px"></i>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <span class="badge text-white font-weight-bold" style="background-color: #D78B00"> {{candidate.exam_start_time}} to  {{candidate.exam_end_time}}</span>
                  </v-col>
                </v-row>
                </v-alert>
              </div>

            <div  v-if="candidate.is_candidate_removed_slot=='1'">
              <v-alert :style="`background:${candidate.background_color} !important`" height="40" text dense rounded>
                <v-row class="d-flex justify-content-between" no-gutters>
                  <v-col cols="6">
                    <span  class="badge text-white " >
                      <b>{{ candidate.break_title }}</b>
                    </span>
                    <v-btn
                        :x-small="true"
                        title="Delete break"
                        class="btn text-white ml-2 btn-standard"
                        @click="deleteBreak(index)"
                    >
                      <i class="fa fa-close"></i>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <span class="badge text-white font-weight-bold" style="background-color: #D78B00"> {{candidate.exam_start_time}} to  {{candidate.exam_end_time}}</span>
                  </v-col>
                </v-row>
              </v-alert>
            </div>

          </div>

          <div   v-if="timetable_assigned_candidates.length<1">
            <v-row no-gutters class="mt-5 ml-5 mr-5">
              <v-col>
                None of the candidates have been scheduled. You can start scheduling by dragging and dropping the candidates from left side. If you want to add breaks you can use the "Add Break" button and choose the required break type.

                <p class="mt-5">  <b>Note:</b> Don't forget to save after arranging the candidates else you will loose your work. </p>
              </v-col>
            </v-row>
          </div>
        </draggable>

        <div class="d-flex justify-content-between my-2 mx-1 mb-2 p-4">
          <div v-if="exam_day.type=='sequential'">
            <strong>Time left:</strong> {{day_time_left}} minutes
          </div>
          <div>
            <strong>Total time :</strong> {{exam_day.total_time_in_minutes}} Minutes
          </div>
        </div>

      </v-card>


    </v-col>
  </v-row>
    <candidate-video-exam ref="video-exam-url" @refresh="setTimetableVideoUrl"></candidate-video-exam>
    <!-- Break Modal -->
    <v-row justify="center">
      <v-dialog
          persistent
          scrollable
          v-model="showAddBreakForm"
          max-width="400"
      >
        <v-card>
          <v-toolbar flat>
            <v-card-title>
              <span>Add break</span>
              <hr>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="showAddBreakForm=false">
                <i class="fa fa-close"></i>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-row class="mt-1">
              <v-col  cols="12">
                <v-select
                    :items="breaks"
                    label="Break title"
                    outlined
                    class="form-select form-select-solid select2-hidden-accessible"
                    dense
                    item-text="title"
                    return-object
                    @change="breakChanged"
                    v-model="break_option.break"
                    :error="$v.break_option.break.$error"
                > </v-select>
              </v-col>

              <v-col  cols="12">
                <label>
                  <strong>Choose break color</strong>
                </label>
                <v-col>
                  <v-swatches v-model="break_option.color"
                              show-fallback
                              swatches="text-advanced"
                              fallback-input-type="color"
                              popover-x="top"
                             >

                  </v-swatches>

                </v-col>
              </v-col>

              <v-col  cols="12" v-if="break_option.break.slug=='other'">
                <v-text-field
                    label="Break description"
                    outlined
                    class="form-select form-select-solid select2-hidden-accessible"
                    dense
                    item-text="title"
                    return-object
                    v-model="break_option.other_title"
                    :error="$v.break_option.other_title.$error"
                > </v-text-field>
              </v-col>

              <v-col cols="6" class="mt-0" v-if="exam_day.type=='manual'">
                <v-select
                    :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]"
                    label="Hour"
                    outlined
                    class="form-select form-select-solid select2-hidden-accessible"
                    dense
                    item-text="title"
                    v-model="break_option.hour"
                    :error="$v.break_option.hour.$error"
                > </v-select>
              </v-col>
              <v-col cols="6" v-if="exam_day.type=='manual'">
                <v-text-field
                    label="Minute"
                    outlined
                    class="form-select form-select-solid select2-hidden-accessible"
                    dense
                    type="number"
                    max-value="59"
                    v-model="break_option.minute"
                    :error="$v.break_option.minute.$error"
                > </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    outlined
                    label="Break duration"
                    v-model="break_option.duration"
                    :error="$v.break_option.duration.$error"
                    class="form-select form-select-solid select2-hidden-accessible"
                    type="number"
                    value="30"
                    dense

                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                x-large
                text
                data-kt-menu-dismiss="true"
                @click="showAddBreakForm=false"
            >
              Clear
            </v-btn>

            <v-btn
                x-large
                dark
                data-kt-menu-dismiss="true"
                @click="addBreak"
            >
              Add break
            </v-btn>



          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--Manual Duration     -->
    <v-row justify="center">
      <v-dialog
          v-model="addManualDuration"
          max-width="290"
      >
        <v-card>
          <v-toolbar flat>
            <v-card-title>
              <span>Set Manual Duration</span>
              <hr>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="addManualDuration=false">
                <i class="fa fa-close"></i>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>

            <v-row  cols="12" class="mt-2">
              <v-col cols="6" class="mt-0">
                <v-select
                    :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]"
                    label="Hour"
                    outlined
                    class="form-select form-select-solid select2-hidden-accessible"
                    dense
                    item-text="title"
                    v-model="manual_duration.hour"
                    :error="$v.manual_duration.hour.$error"
                > </v-select>
              </v-col>


              <v-col cols="6">
                <v-text-field
                    label="Minute"
                    outlined
                    class="form-select form-select-solid select2-hidden-accessible"
                    dense
                    type="number"
                    max-value="59"
                    v-model="manual_duration.minute"
                    :error="$v.manual_duration.minute.$error"
                > </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    outlined
                    label="Duration"
                    v-model="manual_duration.duration"
                    :error="$v.manual_duration.duration.$error"
                    class="form-select form-select-solid select2-hidden-accessible"
                    type="number"
                    dense
                >
                </v-text-field>
              </v-col>
            </v-row>



          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                x-large
                text
                data-kt-menu-dismiss="true"
                @click="addManualDuration=false"
            >
              Close
            </v-btn>

            <v-btn
                x-large
                dark
                data-kt-menu-dismiss="true"
                @click="setManualDuration"
            >
              Set duration
            </v-btn>



          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--  Video URL  -->

  </div>
</template>

<script>
import moment from 'moment';
import draggable from "vuedraggable";
import CandidateDetailModal from "./CandidateDetailModal";
import ExamDayService from '@/services/practical-exam/exam-day/ExamDayService';
import SchedulingRequestService from '@/services/practical-exam/scheduling-request/SchedulingRequestService';
import ProductService from "@/services/product/ProductService";
import GradeService from "@/services/product/grade/GradeService";
import CandidateService from '@/services/candidate/CandidateService';
import {required,numeric, requiredIf,maxValue} from "vuelidate/lib/validators";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import InstrumentService from "@/services/product/instrument/InstrumentService";


import VSwatches from 'vue-swatches'
import CandidateVideoExam from "@/view/pages/view/practical-exam/exam-day/scheduler/CandidateVideoExam";
const examDay = new ExamDayService();
const product = new ProductService();
const schedulingRequest = new SchedulingRequestService;
const grade = new GradeService();
const candidate = new CandidateService();
const syllabus = new SyllabusService();
const instrument = new InstrumentService();


export default {
  components:{
    CandidateVideoExam,
    CandidateDetailModal,
    draggable,
    VSwatches
  },
  props:['exam_day','exam_day_id'],

  validations:{
    break_option:{
      break:{required},
      duration:{required},
      hour:  {required: requiredIf(function () {
          return this.exam_day.type=='manual';
        })
      },
      other_title:{required: requiredIf(function () {
          return this.break_option.break.slug=='other';
        })
      },

      minute:  {required: requiredIf(function () {
          return this.exam_day.type=='manual';
        })
      },
    },
    manual_duration:{
      hour:{required},
      minute:{required,numeric,maxValue:maxValue(60)},
      duration:{required},

  }},

  data(){
    return {
      search:{
        candidate_name:'',
        exam_key:'',
        account_holder_name:'',
        account_holder_email:'',
        teacher_name:'',
        instrument_id:'',
        syllabus_id:'',
        location_id:'',
        grade_id:'',
        exam_id:'',
        exam_name:'',
        group_identifier:'',
        schedule_request_id:'',
        sort_by: ''
      },
      exams: [],
      examSearch:null,
      selectedExam:null,
      scheduling_requests: [],
      instruments: [],
      syllabuses: [],
      grades: [],
      day_time_left:'',
      showAddBreakForm:false,
      addManualDuration:false,
      minHeight:'800px',
      breaks: [],
      break_option:{
        break:'',
        color:'',
        other_title:'',
        duration: '30',
        exam_start_time:'',
        exam_end_time:'',
      },

      manual_duration:{
        index:'',
        hour:'',
        minute: '',
        duration: '',
      },
      timetable_assigned_candidates: [],
      timetable_not_assigned_candidates: [],
      showing_candidates:'',
      total_candidates:'',
      isLoading:false,
      candidate_infos:[],
      sortByValues:[
        {
          name: 'Scheduling request (ASC)',
          value: 'scheduling_request_asc'
        },
        {
          name: 'Scheduling request (DESC)',
          value: 'scheduling_request_desc'
        },
        {
          name: 'Candidate first name (ASC)',
          value: 'candidate_first_name_asc'
        },
        {
          name: 'Candidate first name (DESC)',
          value: 'candidate_first_name_desc'
        },
        {
          name: 'Teacher name (ASC)',
          value: 'teacher_name_asc'
        },
        {
          name: 'Teacher name (DESC)',
          value: 'teacher_name_desc'
        },
        {
          name: 'Grade - low to high',
          value: 'grade_low_to_high'
        },
        {
          name: 'Grade - high to low',
          value: 'grade_high_to_low'
        },
      ]
    }
  },
  watch:{
    examSearch(val) {
      let data = {
        name:val,
        type:'practical_exam'
      };
      product
          .searchByType(data)
          .then((response) => {
            response.data.products.map((product) => {
              let data = product;
              data.display_text = product.name;
                  this.exams.push(data);
            });
          })
          .catch((err) => {

          })
          .finally(() => (this.isLoading = false));
    },


    selectedExam(val)
    {
      if(!val)
        this.search.exam_id ='';
    },

    selectedSession(val)
    {
      if(!val)
        this.search.session_id ='';
    },

  },
  methods:{
    getAllGrades() {
      grade.all().then(response => {
        this.grades = response.data.grades;
      });
    },
    getAllSchedulingRequest(){
      this.isLoading = true;
      schedulingRequest
          .getAllSchedulingRequest()
          .then(response => {
            this.isLoading = false;
            this.scheduling_requests=response.data.scheduling_requests;
          })
          .catch((err) => {

          });
    },

    changeToUnassigned: function({added}) {
      if(added)
      {
        added.element.is_timetable_assigned = 0;
        added.element.is_manual_time_applied = 0;
        this.calculateTimeForCandidates(this.timetable_assigned_candidates);
      }


    },

    changeToAssigned: function({added,moved}) {
      if(added)
      {
        added.element.is_timetable_assigned = 1;

        if(this.exam_day.type=='sequential' || this.exam_day.type=='constant')
        {
          this.calculateTimeForCandidates(this.timetable_assigned_candidates);
          this.saveScheduledCandidates(false);
        }

        if(this.exam_day.type=='manual')
        {
          this.calculateTimeForManualCandidate(added.element,added.newIndex);
          this.saveScheduledCandidates(false);
        }

      }

      if(moved)
      {
        if(this.exam_day.type=='sequential' || this.exam_day.type=='constant') {
          this.calculateTimeForCandidates(this.timetable_assigned_candidates);
          this.saveScheduledCandidates(false);
        }

        if(this.exam_day.type=='manual')
        {
          this.calculateTimeForManualCandidate(moved.element,moved.newIndex);
          this.saveScheduledCandidates(false);
        }
      }

    },

    moveToRight(index,candidate)
    {
      this.timetable_not_assigned_candidates.splice(index, 1);
      this.timetable_assigned_candidates.push(candidate);
      this.calculateTimeForCandidates(this.timetable_assigned_candidates);
      this.saveScheduledCandidates(false);
    },

    moveToLeft(index,candidate)
    {
      this.timetable_assigned_candidates.splice(index, 1);
      this.timetable_not_assigned_candidates.push(candidate);
      this.calculateTimeForCandidates(this.timetable_assigned_candidates);
    },

    calculateTimeForCandidates(candidates)
    {
      this.day_time_left = this.exam_day.total_time_in_minutes;
      if (this.exam_day.type=='sequential')
      {
        let exam_day_start_time = this.exam_day.exam_start_time;
        candidates.forEach((candidate,index) => {

          if(index==0){
            candidate.exam_start_time = moment(exam_day_start_time, 'HH:mm A').format('HH:mm A');
            candidate.exam_end_time = moment(exam_day_start_time, 'HH:mm A').add(candidate.exam_duration, 'minutes').format('HH:mm A');
            this.setAvailableDayTime(candidate.exam_duration);

          }else{
            let start_time = moment(candidates[index-1].exam_end_time, 'HH:mm A').format('HH:mm A');
            let end_time = moment(start_time, 'HH:mm A').add(candidate.exam_duration, 'minutes').format('HH:mm A');
            candidate.exam_start_time = start_time;
            candidate.exam_end_time = end_time;
            this.setAvailableDayTime(candidate.exam_duration);
          }
        });
        this.timetable_assigned_candidates = candidates;

      }

      if (this.exam_day.type=='constant')
      {
        let exam_day_start_time = this.exam_day.exam_start_time;
        candidates.forEach((candidate,index) => {
            let start_time = moment(exam_day_start_time, 'HH:mm A').format('HH:mm A');
            let end_time = moment(exam_day_start_time, 'HH:mm A').add(candidate.exam_duration, 'minutes').format('HH:mm A');
            candidate.exam_start_time = start_time;
            candidate.exam_end_time = end_time;

        });
        this.timetable_assigned_candidates = candidates;
      }




    },

    calculateTimeForManualCandidate(selectedCandidate,newIndex)
    {

    if(this.timetable_assigned_candidates.length>1)
      {
        let previousCandidate = this.timetable_assigned_candidates[newIndex-1];
        if(previousCandidate && selectedCandidate.is_manual_time_applied=='0')
        {
          selectedCandidate.exam_start_time = moment(previousCandidate.exam_end_time, 'HH:mm A').format('HH:mm A');
          selectedCandidate.exam_end_time = moment(previousCandidate.exam_end_time, 'HH:mm A').add(selectedCandidate.exam_duration, 'minutes').format('HH:mm A');
        }

      }

      if(newIndex==0 && selectedCandidate.is_manual_time_applied=='0')
      {
        selectedCandidate.exam_start_time = moment(this.exam_day.exam_start_time, 'HH:mm A').format('HH:mm A');
        selectedCandidate.exam_end_time = moment(this.exam_day.exam_start_time, 'HH:mm A').add(selectedCandidate.exam_duration, 'minutes').format('HH:mm A');
      }


    },

    setAvailableDayTime(duration)
    {

      if (this.day_time_left< duration)
      {
        this.$snotify.warning('Candidate exam duration exceeded the available minutes for this Schedule.',{
          timeout:8000,
          oneAtTime:true
        });
      }

      if(this.day_time_left> duration)
      {
        this.day_time_left -= duration;
      }
      else
      {
        this.day_time_left = 0;
      }
    },

    getTimetableAssignedCandidates()
    {
      if(this.exam_day.id)
      {
        examDay.getTimetableAssignedCandidates(this.exam_day.id).then(response=>{
          this.timetable_assigned_candidates = response.data.candidates;
          this.calculateTimeForCandidates(this.timetable_assigned_candidates);
          this.setHeightForNonScheduledDraggable(this.timetable_assigned_candidates.length)

        });
      }

    },

    setHeightForNonScheduledDraggable(noOfCandidates)
    {
      let remainder = parseInt(noOfCandidates)/5;
      let minHeight = 800* Math.ceil(remainder);
      this.minHeight = minHeight+'px';

    },

    getTimetableNotAssignedCandidates(loader=true)
    {
      if(this.exam_day.id)
      {

        if(loader)
        {
          this.isLoading = true;
        }

        this.search.location_id = this.exam_day.location_id;
        examDay.getTimetableNotAssignedCandidates(this.exam_day.id,this.search).then(response=>{
          this.timetable_not_assigned_candidates = response.data.candidates;
          this.showing_candidates = response.data.total_summary.showing_candidates;
          this.total_candidates = response.data.total_summary.total_candidates;
          this.isLoading = false;
        });
      }

    },

    getBreaks()
    {
      examDay.getBreaks().then(response=>{
           this.breaks = response.data.breaks;
        });

    },

    breakChanged()
    {
      this.break_option.color = this.break_option.break.color;
      this.break_option.duration = this.break_option.break.duration;
    },

    addBreak()
    {
      this.$v.$touch()
      if (this.$v.break_option.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
         let start_time = this.break_option.hour+':'+this.break_option.minute;
         let break_object = {
           'id':'',
           'timetable_id':'',
           'full_name':'',
           'exam_key':'',
           'schedule_request_id':'',
           'scheduling_request':'',
           'scheduling_request_color':'',
           'scheduling_remarks':'',
           'account_holder_email':'',
           'grade_name':'',
           'syllabus_name':'',
           'grade_short_name':'',
           'exam_duration':this.break_option.duration,
           'has_candidate_attachments':'',
           'is_break':1,
           'break_title':this.break_option.break.slug=='other'? this.break_option.other_title:this.break_option.break.title,
           'background_color':this.break_option.color!=''?this.break_option.color:this.break_option.break.color,
           'is_candidate_removed_slot':'',
           'is_empty_slot':this.break_option.break.is_empty_slot?this.break_option.break.is_empty_slot:0,
           'is_timetable_assigned':1,
           'exam_start_time':moment(start_time, 'HH:mm A').format('HH:mm A'),
           'exam_end_time':moment(start_time, 'HH:mm A').add(this.break_option.duration,'minutes').format('HH:mm A'),
           'is_saved':'',
           'is_manual_time_applied':'',
           'class_exam_identifier':''
         }
         this.timetable_assigned_candidates.push(break_object);
         // this.break_option.break = '';
         this.showAddBreakForm = false;
        // this.$v.$reset();
         this.calculateTimeForCandidates(this.timetable_assigned_candidates);
      }

    },

    deleteBreak(index)
    {
      let breakInfo = this.timetable_assigned_candidates[index];
       if(breakInfo.timetable_id!='')
       {
         examDay.deleteBreak(breakInfo.timetable_id).then(response=>{
           this.$snotify.success('Break deleted.');
         });
       }
       this.timetable_assigned_candidates.splice(index,1);
       this.calculateTimeForCandidates(this.timetable_assigned_candidates);
    },
    sortTime()
    {

      this.calculateTimeForCandidates(this.timetable_assigned_candidates);
    },

    setManualDurationModal(candidateIndex)
    {
      this.addManualDuration = true;
      this.manual_duration.index = candidateIndex;

    },

    setManualDuration()
    {

      this.$v.$touch()
      if (this.$v.manual_duration.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        let start_time = this.manual_duration.hour+':'+this.manual_duration.minute;
            start_time = moment(start_time, 'HH:mm').format('HH:mm A');
        let end_time = moment(start_time, 'HH:mm').add(this.manual_duration.duration, 'minutes').format('HH:mm A');
        let candidate =this.timetable_assigned_candidates[this.manual_duration.index];
           candidate.exam_start_time = start_time;
           candidate.exam_end_time = end_time;
           candidate.exam_duration = this.manual_duration.duration;
           candidate.is_manual_time_applied = 1;

        this.timetable_assigned_candidates.splice(this.manual_duration.index, 1,  candidate);
            this.addManualDuration = false;
            this.manual_duration ={
              index:'',
                  hour:'',
                  minute: '',
                  duration: '',
            };
            this.$v.$reset();
      }

    },
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },
    viewCandidateInformation(examKey)
    {
      candidate.getCandidateSummary(examKey).then(response=>{

        let candidate = response.data.candidate;
        let candidateExist = this.candidate_infos.find(data => data.exam_key === candidate.exam_key);
        if(!candidateExist)
        {
          this.candidate_infos.push(candidate);
        }

        let element = this.$refs['candidate-infos'];
        let top = element.offsetTop;
        window.scrollTo(0, top);
      });

    },
    viewCandidateSummary(examKey)
    {
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },

    changeCandidateToIndividualOrGroup(index,type){
      if(type=='assigned')
      {
        let candidate = this.timetable_assigned_candidates[index];
        examDay.setCandidateToIndividualOrGroup(candidate).then(response=>{
          this.timetable_assigned_candidates.splice(index, 1,  response.data.candidate);
          if(response.data.candidate.is_grouped==1)
          {
            this.$snotify.success('Candidate changed to group');
          }
          if(response.data.candidate.is_grouped==0)
          {
            this.$snotify.success('Candidate changed to individual');
          }
        });
      }

      if(type=='unassigned')
      {
        let candidate = this.timetable_not_assigned_candidates[index];
        examDay.setCandidateToIndividualOrGroup(candidate).then(response=>{
          this.timetable_not_assigned_candidates.splice(index, 1,  response.data.candidate);
          if(response.data.candidate.is_grouped==1)
          {
            this.$snotify.success('Candidate changed to group');
          }
          if(response.data.candidate.is_grouped==0)
          {
            this.$snotify.success('Candidate changed to individual');
          }
        });
      }

    },

    changeCandidateToLockedOrUnlocked(index)
    {
      let candidate = this.timetable_assigned_candidates[index];
      examDay.setCandidateToLockedOrUnlocked(candidate).then(response=>{
        this.timetable_assigned_candidates.splice(index, 1,  response.data.candidate);
        if(response.data.candidate.is_grouped==1)
        {
          this.$snotify.success('Candidate has been locked');
        }
        if(response.data.candidate.is_grouped==0)
        {
          this.$snotify.success('Candidate has been unlocked');
        }
      });
    },

    removeCandidateAndCreateEmptySlot(index)
    {
      this.$confirm({
        message: `Are you sure you want to remove candidate? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let candidate = this.timetable_assigned_candidates[index];
            examDay.removeCandidateAndSaveEmptySlot(candidate).then(response=>{
              this.timetable_assigned_candidates.splice(index, 1,  response.data.candidate);
                this.$snotify.success('Candidate removed successfully.');
                this.getTimetableNotAssignedCandidates();

            });
          }
        },
      });

    },
    selectExam()
    {
      if(this.selectedExam)
        this.search.exam_id = this.selectedExam.id;
    },

    saveScheduledCandidates(showMessage = true)
    {
       if(this.timetable_assigned_candidates.length>0)
       {
         let candidates = [];

         this.timetable_assigned_candidates.forEach((candidate,index) => {

           candidate.exam_start_time = moment(candidate.exam_start_time , 'HH:mm').format('HH:mm');
           candidate.exam_end_time = moment( candidate.exam_end_time, 'HH:mm').format('HH:mm');
           candidates.push(candidate)
         });
          let data ={
            exam_day_id:this.exam_day_id,
            candidates:candidates,
          }
          examDay.saveCandidateTimetable(data).then(response=>{
            if(showMessage)
            {
              this.$snotify.success('Timetable saved successfully');
            }
            this.getTimetableAssignedCandidates();
            this.getTimetableNotAssignedCandidates();
            this.$emit('refresh');
          }).catch(error=>{
            this.$snotify.error('Problem Occurred')
          });

       }
       else
       {
         if(showMessage) {
           this.$snotify.error('Please start moving enrolments from left side to start scheduling');
         }
       }
    },

    saveAndConfirm()
    {
      this.$confirm({
        message: `Are you sure ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            if(this.timetable_assigned_candidates.length>0)
            {
              let candidates = [];

              this.timetable_assigned_candidates.forEach((candidate,index) => {

                candidate.exam_start_time = moment(candidate.exam_start_time , 'HH:mm').format('HH:mm');
                candidate.exam_end_time = moment( candidate.exam_end_time, 'HH:mm').format('HH:mm');
                candidates.push(candidate)
              });
              let data ={
                exam_day_id:this.exam_day_id,
                candidates:candidates,
              }
                examDay.saveCandidateTimetable(data).then(response=>{
                  this.getTimetableAssignedCandidates();
                  this.getTimetableNotAssignedCandidates(false);
                  examDay.saveAndConfirm(this.exam_day.id).then(response=>{
                    this.$snotify.success('Schedule has been confirmed.');
                    this.$emit('refresh');

                  });
                }).catch(error=>{
                });
            }
            else
            {
              this.$snotify.error('Please start moving enrolments from left side to start scheduling.')
            }
          }
        },
      });
    },

    removeCandidateFromInfo(index){
      this.candidate_infos.splice(index, 1);
    },
    getAllInstruments() {
      instrument.getByExamSession(this.exam_day.exam_session_id,this.exam_day.category_ids).then(response => {
        this.instruments = response.data.data;
      });
    },
    getAllSyllabus()
    {
      syllabus.getByExamSession(this.exam_day.exam_session_id,this.exam_day.instrument_ids).then(response => {
        this.syllabuses = response.data.data;
        this.isLoading=false;
      });
    },

    setVideoUrlModal(candidateIndex)
    {
      let candidate = this.timetable_assigned_candidates[candidateIndex];
      this.$refs['video-exam-url'].openDialog(candidate);
      //this.manual_duration.index = candidateIndex;

    },

    setTimetableVideoUrl(candidateInfo)
    {
      let candidate = this.timetable_assigned_candidates.find(data => data.exam_key === candidateInfo.exam_key);
      if(candidate)
      {
        candidate.video_exam_url = candidateInfo.video_exam_url;
      }
    }


  },
  mounted() {
    this.day_time_left = this.exam_day.total_time_in_minutes;
    this.getTimetableAssignedCandidates();
    this.getTimetableNotAssignedCandidates();
    this.getAllSchedulingRequest();
    this.getAllGrades();
    this.getBreaks();
    this.getAllInstruments();
    this.getAllSyllabus();

  },
};
</script>
<style>

</style>




