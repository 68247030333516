<template>
    <v-dialog
        v-model="showSupervisorEmailDialog"
        max-width="800"
        scrollable
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span> Supervisor confirmation notification </span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr class="text-left">
                                            <th></th>
                                            <th class="px-3">Supervisor</th>
                                            <th class="px-3">Status</th>
                                            <th class="px-3">Notified date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr 
                                            v-if="examDayConfirmationLogs" 
                                            v-for="(item,index) in examDayConfirmationLogs"
                                            :key="index"
                                        >
                                            <td class="px-2">
                                                <v-checkbox
                                                    v-if="!item.is_confirmed"
                                                    :value="item.supervisor_id"
                                                    v-model="supervisor_mails.confirmation_supervisors"
                                                ></v-checkbox>
                                                <span class="ml-5 mt-4" v-else>
                                                    <i class="fa fa-check-circle"></i>    
                                                </span>
                                            </td>
                                            <td class="px-2">
                                                <div class="mb-2">
                                                {{ item.full_name }}<br/>
                                                  <small class="">
                                                {{ item.email }}
                                                </small>
                                                </div>
                                                
                                                <span v-if="item.is_confirmed" class="badge badge-primary">
                                                    Confirmed
                                                </span>
                                            </td>

                                            <td class="px-2">
                                                <span 
                                                    v-if="! item.is_confirmed"
                                                    class="badge"
                                                    :class="item.sent_at ? 'badge-success' : 'badge-warning' "
                                                >
                                                    {{ item.sent_at ? 'Sent' : 'Pending' }}
                                                </span>

                                                <span 
                                                    v-if="item.is_confirmed"
                                                    class="badge badge-primary"
                                                >
                                                Confirmed
                                                </span>
                                            </td>
                                          <td>
                                            {{item.sent_at ? item.sent_at : 'NA'}}
                                          </td>
                                        </tr>
                                        <tr v-if="$v.supervisor_mails.confirmation_supervisors.$error">
                                            <td colspan="4" class="text-danger text-center">
                                                Please choose an examiner to send mail. 
                                            </td>
                                        </tr>
                                        <tr v-if="!examDayConfirmationLogs">
                                            <td colspan="4" class="text-center">
                                                No data available
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                   text
                   x-large
                    @click="closeDialog"
                >
                Cancel
                </v-btn>
              <v-btn
                  dark
                  x-large
                  @click="sendSupervisorsMail"
                  :loading="loading"
              >
                Send
              </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";

import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";

const examDay=new ExamDayService();

export default {
    validations:{
        supervisor_mails:{
            confirmation_supervisors: {required},
        }
    },
    data(){
        return{
            showSupervisorEmailDialog: false,
            examDayId: null,
            examDayConfirmationLogs: [],
            loading: false,
            supervisor_mails:{
                confirmation_supervisors:[],
                exam_day_id: null
            },
        }
    },
    methods:{
        showDialog(examDayId){
            this.examDayId = examDayId;
            this.getSupervisorConfirmations();
            this.showSupervisorEmailDialog = true;
        },
        getSupervisorConfirmations(examDayId){
            examDay
            .getSupervisorConfirmations(this.examDayId)
            .then((response) => {
                this.examDayConfirmationLogs = response.data.examDayConfirmationLogs;
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        },
        closeDialog(){
            this.resetForm();
            this.showSupervisorEmailDialog = false;
        },
        resetForm(){
            this.supervisor_mails = {
                confirmation_supervisors:[],
                exam_day_id: null
            };
            this.$v.$reset();
        },
        sendSupervisorsMail(){
            this.$v.$touch()
            if (this.$v.$error) {
                setTimeout(() => {
                this.$v.$reset()
                }, 3000);
            } else {
                this.loading = true;
                this.supervisor_mails.exam_day_id = this.examDayId;
                examDay
                .doAskSupervisorAvailability(this.supervisor_mails)
                .then((response) => {
                    this.closeDialog();
                    this.resetForm();
                    this.$emit('refresh');
                    this.$snotify.success('Confirmation email sent to supervisor sent successfully.')
                    this.loading = false;
                })
                .catch((err) => {
                    this.$snotify.error('Confirmation email could not be sent.')
                    this.loading = false;
                    this.closeDialog();
                    this.resetForm();
                    this.$emit('refresh');
                })
                .finally(() => {
                    this.loading = false;
                    this.closeDialog();
                    this.resetForm();
                    this.$emit('refresh');
                });
            }
        }
    }
}
</script>
