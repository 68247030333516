<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
      <v-card>
        <v-toolbar dark>
          <v-card-title>
            <span>Candidate Information</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      <v-card-text>
        <v-container>
          <div class="form-block">
            <v-row>
              <v-col cols="12">
               <b>Candidate Name:</b> {{candidate.full_name}}<br>
               <b>Enrolment key:</b> {{candidate.exam_key}}<br>
               <b>DOB:</b> {{candidate.dob}}<br>
               <b>Scheduling remarks:</b> {{candidate.scheduling_remarks}}<br>
               <b>Enrolment status:</b> <span v-html="candidate.enrolment_status_badge"></span> <br>
              </v-col>

              <v-col cols="12">
                <hr>
                <h5>Accountholder Info</h5>
                <b>Name:</b> {{candidate.owner.full_name}}<br>
                <b>Email:</b> {{candidate.owner.email}}<br>

              </v-col>

              <v-col cols="12">
                <hr>
                <h5>Exam Info</h5>
                <b>Instrument:</b> {{candidate.instrument_name}}<br>
                <b>Syllabus:</b> {{candidate.syllabus_name}}<br>
                <b>Grade:</b> {{candidate.grade}}<br>
                <b>Location:</b> {{candidate.location}}<br>
                <b>Exam name:</b> {{candidate.exam_name}}<br>
              </v-col>


              <v-col cols="12" v-if="candidate.has_teacher_info=='1'">
                <hr>
                <h5>Teacher Info</h5>
                <b>Name:</b> {{candidate.teacher_full_name}}<br>
                <b>Email:</b> {{candidate.teacher_email}}<br>
              </v-col>

              <v-col cols="12" v-if="candidate.has_parent_info=='1'">
                <hr>
                <h5>Parent/guardian info</h5>
                <b>Parent/guardian Full name:</b> {{candidate.parent_full_name}}<br>
                <b>Parent/guardian email:</b> {{candidate.parent_email}}<br>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
        >Close</v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import CandidateService from '@/services/candidate/CandidateService';
const candidate = new CandidateService();

export default {
  components:{
  },
  props:['candidate_id'],
  data(){
    return {
      dialog:false,
      candidate:{},
    }
  },
  methods:{
    openDialog(examKey)
    {
      candidate.getCandidateSummary(examKey).then(response=>{
        this.dialog = true;
        this.candidate = response.data.candidate;
      });
    },

    closeDialog()
    {
      this.dialog = false;
    }
  },
  mounted() {

  },
};
</script>