<template>
    <v-dialog
        v-model="showVenueConfirmationDialog"
        max-width="800"
        scrollable
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span> Venue confirmation </span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <p class="font-size-lg">
                                <strong>Venue name: </strong> {{examDay ? examDay.venue_name :'NA'}}
                            </p>
                            <p class="font-size-lg">
                                <strong>Venue email: </strong> {{examDay ? examDay.venue_email ? examDay.venue_email :'NA' : 'NA'}}
                            </p>
                            <p class="font-size-lg">
                                <strong>Venue phone: </strong> {{examDay ? examDay.venue_phone :'NA'}}
                            </p>
                            <p class="font-size-lg">
                                <strong>Contact person name: </strong> {{examDay ? examDay.venue_contact_person_full_name :'NA'}}
                            </p>
                            <p class="font-size-lg">
                                <strong>Contact person phone: </strong> {{examDay ? examDay.venue_contact_person_phone :'NA'}}
                            </p>
                            <p class="font-size-lg">
                                <strong>Contact person email: </strong> {{examDay ? examDay.venue_contact_person_email :'NA'}}
                            </p>
                            <p class="font-size-lg">
                                <strong>Address: </strong> {{examDay ? examDay.venue_contact_person_full_address :'NA'}}
                            </p>
                            <p class="font-size-lg">
                                <strong>Address line 2: </strong> {{examDay ? examDay.venue_contact_address_line_2 ? examDay.venue_contact_address_line_2 :'NA' :'N/A'}}
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
              <v-btn
                  class="cancel-btn"
                  medium
                  @click="closeDialog"
              >
                Cancel
              </v-btn>


                <v-btn
                    color="text-white btn btn-primary"
                    dark
                    @click="sendVenueMail"
                    medium
                    :loading="loading"
                >
                Send
                </v-btn>


            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";

const examDay=new ExamDayService();

export default {
    data(){
        return {
            showVenueConfirmationDialog: false,
            examDay: null,
            loading: false,
        }
    },
    methods:{
        showDialog(examDay){
            this.showVenueConfirmationDialog = true;
            this.examDay = examDay;
        },
        closeDialog(){
            this.showVenueConfirmationDialog = false;
        },
        sendVenueMail(){
            this.loading = true;
            examDay
            .sendVenueMail(this.examDay.id)
            .then((response) => {
                this.closeDialog();
                this.$snotify.success('Venue confirmation sent successfully.');
                this.loading = false;
                this.$emit('refresh');
            })
            .catch((err) => {
                this.$snotify.error(err.message);
                this.closeDialog();
                this.loading = false;
                this.$emit('refresh');
            })
        }
    }    
}
</script>
