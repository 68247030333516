<template>
  <v-dialog v-model="dialog" max-width="800" hide-overlay
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Schedule examiners</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>

        <v-row no-gutters>
          <table class="table">
            <thead>
            <tr>
              <th>Examiner name</th>
              <th>Chief </th>
              <th>Trainee </th>
              <th>Options</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="examDayDetail && examDayDetail.examiner_list.length>0" v-for="(examiner, index) in examDayDetail.examiner_list" :key="index">
              <td>{{examiner.full_name}}</td>
              <td>{{examiner.id==examDayDetail.chief_examiner_id ? `Yes`:`No`}}</td>
              <td>{{examDayDetail.trainee_examiner_ids && examDayDetail.trainee_examiner_ids.includes(examiner.id) ? `Yes`:`No`}}</td>
              <td>
                <b-dropdown
                    size="sm"
                    variant="link"
                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                    no-caret
                    right
                    no-flip
                >
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">


                    <b-dropdown-text tag="div" class="navi-item">
                      <a class="navi-link" @click="updateChiefAndTraineeExaminer(examiner.id,'chief')" v-if="checkCanSetAsChief(examiner.id)">
                               <span class="navi-icon">
                                  <i class="fas fa-check"></i>
                                </span>
                        <span class="navi-text">Set as a chief examiner</span>
                      </a>
                    </b-dropdown-text>

                    <b-dropdown-text tag="div" class="navi-item" v-if="examDayDetail.chief_examiner_id!=examiner.id && checkCanSetAsTrainee(examiner.id)">
                      <a class="navi-link" @click="updateChiefAndTraineeExaminer(examiner.id,'trainee')" >
                               <span class="navi-icon">
                                  <i class="fas fa-check"></i>
                                </span>
                        <span class="navi-text">Set as a trainee examiner </span>
                      </a>
                    </b-dropdown-text>

                    <b-dropdown-text tag="div" class="navi-item">
                      <a class="navi-link" @click="deleteExaminer(examiner.id)">
                               <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                        <span class="navi-text">Delete</span>
                      </a>
                    </b-dropdown-text>


                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
               </td>
            </tr>
            <tr v-if="examDayDetail && !examDayDetail.examiner_list">
              <td colspan="4">Examiner not available</td>
            </tr>
            </tbody>
          </table>
        </v-row>
        <v-divider></v-divider>
        <v-container>


          <v-row>
            <v-col md="5" v-if="checkExaminer">
              <label for="#">Check examiner availability</label>
              <v-autocomplete :error="$v.examinerAvailabilityDetail.$error" label="Search examiner by name or email"
                  outlined dense
                  v-model="examinerAvailabilityDetail"    :items="available_examiners"  :search-input.sync="availableExaminerSearch"
                  cache-items   item-text="full_name" return-object class="ml-2"
              ></v-autocomplete>
              <span class="text-danger" v-if="$v.examinerAvailabilityDetail.$error">This information is required</span>
              <p v-if="$v">{{$v.examinerAvailabilityDetail.error}}</p>

            </v-col>
            <v-col  md="5" v-if="checkExaminer">
              <label for="#">Date</label>
              <span class="d-flex justify-content-between">
                        <v-menu
                            ref="menu"
                            no-title
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="Select Date"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense v-model="exam_date"
                                outlined
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                no-title
                                v-model="exam_date"
                            ></v-date-picker>
                        </v-menu>
                        <v-btn
                            color="black lighten-3"
                            class="text-white ml-2 mt-1"
                            @click="checkExaminerAvailability"
                        >
                            Check
                        </v-btn>
                    </span>
            </v-col>
            <v-divider></v-divider>
            <v-col :md="checkExaminer?2:12" class="text-right">
              <a @click="checkExaminerAvailabilityToggle" title="Check examiner availability">
                <i :class="!checkExaminer?`fas fa-search`:`fas fa-close`" style="height: 60px"></i>
              </a>
            </v-col>

            <v-alert v-if="examinerCheckError==2"
                     class="mt-0 ml-4 text-center"
                     :icon="true"
                     dense
                     outlined
                     type="success"
            >
              <b>Great, {{examinerAvailabilityDetail.full_name}} is available for {{exam_date}}</b>
            </v-alert>

            <v-alert v-if="examinerCheckError==1"
                     class="mt-0 ml-4 text-center"
                     :icon="true"
                     dense
                     outlined
                     type="warning"
            >
              <b>Sorry, {{examinerAvailabilityDetail.full_name}} is not available for {{exam_date}}</b>
            </v-alert>


          </v-row>
          <v-row>
            <v-col cols="12" md="5">
              <label for="#">Search Examiner</label>
                <v-autocomplete @change="changeExaminers" v-model="examinerDetail" label="Search examiner by name or email" outlined dense :items="examiners"  :search-input.sync="examinerSearch"
                                cache-items   item-text="full_name" return-object >
                </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="examiner_lists.length > 0" v-for="(examiner,index) in examiner_lists">
            <v-col md="7">
              <div style="display: inline-block">
                 <span class="font-weight-bold">{{examiner.full_name}}</span>  <span class="">( {{examiner.email}} )</span>
              </div>
            </v-col>
              <v-col md="2">
                <v-checkbox @click="changeChiefExaminer(index)" input-value="false" label="Chief" v-model="examiner.chief_examiner_id">
                </v-checkbox>
              </v-col>
            <v-col md="2">
                <v-switch v-model="examiner.trainee_examiner_ids"  @click="changeTraineeExaminer(index)" label="Trainee"
                ></v-switch>
            </v-col>
              <v-col md="1">
                <a href="#!" @click="removeExaminer(index)" class="ml-2">
                  <i class="fa fa-trash"></i>
                </a>
              </v-col>
          </v-row>

        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
           text
            x-large
            @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn v-if="examiner_lists.length>0"
              x-large
               dark
               @click="updateExaminers()"
               :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ExaminerService from "@/services/user/examiner/ExaminerService";
import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";
import { required, requiredIf } from "vuelidate/lib/validators";

const examDay=new ExamDayService();
const examiner=new ExaminerService();
export default
{
  validations: {
    examinerAvailabilityDetail:{required},
  },
  props:['exam_day'],
  data(){
    return{
      dialog:false,
      loading:false,
      examinerCheckError:0,
      examinerSearch:'',
      availableExaminerSearch:'',
      checkExaminer:false,
      examiners:[],
      available_examiners:[],
      examiner_lists:[],
      examinerDetail:{},
      examinerAvailabilityDetail:{},
      exam_day_info:{
        id:'',
        examiner_ids:'',
        chief_examiner_id:'',
        trainee_examiner_ids:'',
      },
      exam_date:'',
      examDayDetail:{},
    }
  },
  methods:{
    openDialog(examId){
      this.dialog=true;
      this.exam_day_info.id=examId;
      this.findExamDayDetail(examId);

    },
    closeDialog(){
      this.dialog=false;
      this.resetData();
    },
    buildData(){

      if(this.examiner_lists.length > 0){
        let examiner_ids=[];
        let chief_examiner_id='';
        let trainee_examiner_ids=[];
        this.examiner_lists.forEach((value, index) => {
          examiner_ids.push(value.id);
          if(value.trainee_examiner_ids){
            trainee_examiner_ids.push(value.id);
          }
          if(value.chief_examiner_id){
            chief_examiner_id=value.id;
          }
        });
        this.exam_day_info.examiner_ids=examiner_ids;
        this.exam_day_info.trainee_examiner_ids=trainee_examiner_ids;
        this.exam_day_info.chief_examiner_id=chief_examiner_id;
      }
    },
    updateExaminers(){
      this.buildData();
      examDay
          .assignExaminer(this.exam_day.id,this.exam_day_info)
          .then((response) => {
              this.$emit('refresh')
              this.$snotify.success('Examiner(s) saved successfully.');
              this.dialog = false;
              this.examiner_lists = [];
              this.resetData();
          })
          .catch(err => {
            this.$snotify.error('Problem Occurred');
            this.dialog = false;

          })
          .finally(() => (this.loading = false))

    },

    deleteExaminer(examinerId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let data = {
              exam_day_id:this.examDayDetail.id,
              examiner_id:examinerId,
            };
            examDay
                .deleteExaminer(data)
                .then((response) => {
                  this.$emit('refresh')
                  this.$snotify.success('Examiner deleted');
                  this.dialog = false;
                  this.examiner_lists = [];
                  this.resetData();
                })
                .catch(err => {
                  this.$snotify.error('Problem Occurred');
                  this.dialog = false;

                })
                .finally(() => (this.loading = false))
          }
        },
      });
    },
    changeExaminers(){
      let obj = null;
      if(this.examiner_lists.length > 0){
        let index=this.examiner_lists.findIndex(val =>val.id==this.examinerDetail.id);
        obj = this.examiner_lists[index];
      }
      if(obj == undefined || obj == null){
        let data={'id':this.examinerDetail.id,'full_name':this.examinerDetail.full_name,'email':this.examinerDetail.email,'trainee_examiner_ids':false,'chief_examiner_id':false}
        this.examiner_lists.push(data);
      }else{
        this.$snotify.error('Examiner has been already added');
      }
      this.examinerDetail={};
      this.examinerSearch='';
    },
    removeExaminer(index){
      this.examiner_lists.splice(index,1);
    },
    changeChiefExaminer(index){
      this.examiner_lists.filter((value, index) => {
        value.chief_examiner_id=false;

      });
      this.examiner_lists[index].chief_examiner_id=true;
      this.examiner_lists[index].trainee_examiner_ids=false;
    },

    changeTraineeExaminer(index){

      this.examiner_lists[index].chief_examiner_id=false;
     // this.examiner_lists[index].trainee_examiner_ids=true;
    },
    findExamDayDetail(examDayId){
      examDay
          .show(examDayId)
          .then((response) => {
            this.examDayDetail=response.data.examDay;
           this.exam_date=this.examDayDetail.exam_date;

          })
          .catch(err => {

          })
          .finally(() => (this.loading = false))

    },

    checkExaminerAvailabilityToggle()
    {
      this.checkExaminer = !this.checkExaminer;
    },

    checkExaminerAvailability() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        let data = {
          examiner_id: this.examinerAvailabilityDetail.id,
          exam_date: this.exam_date,
          exam_day_id: this.examDayDetail.id,
        }
        examDay
            .checkExaminerAvailability(data)
            .then((response) => {

              if (response.data.status == 'ERROR') {
                this.examinerCheckError = 2;
              }
              if (response.data.status == 'OK') {
                this.examinerCheckError = 1;
              }

            })
            .catch(err => {
            })
      }
    },

    checkCanSetAsChief(examinerId)
    {
      let flag =false;

      if(this.examDayDetail.trainee_examiner_ids)
      {
        if (this.examDayDetail.trainee_examiner_ids.includes(examinerId))
        {
          flag = false;
        }

      }

      if(!this.examDayDetail.trainee_examiner_ids)
      {
        flag =true;
      }


      if (!this.examDayDetail.chief_examiner_id)
      {
        flag =true;
      }

      if (this.examDayDetail.chief_examiner_id==examinerId)
      {
        flag =false;
      }

      if (this.examDayDetail.chief_examiner_id)
      {
        flag =false;
      }



      return flag;
    },

    checkCanSetAsTrainee(examinerId)
    {
      let flag =false;

      if(this.examDayDetail.trainee_examiner_ids)
      {
        if (this.examDayDetail.trainee_examiner_ids.includes(examinerId))
        {
          flag = false;
        }
        else
        {
          flag = true;
        }

      }
      else if(!this.examDayDetail.trainee_examiner_ids)
      {
        flag =true;
      }

      return flag;
    },

    updateChiefAndTraineeExaminer(examinerId,type)
    {
      let data ={
        exam_day_id:this.examDayDetail.id,
        examiner_id:examinerId,
        type:type
      }

      examDay
          .updateChiefAndTraineeExaminer(data)
          .then((response) => {
            this.$snotify.success('Examiner updated');
            this.$emit('refresh');
            this.dialog = false;
          }).catch(error=>{
        this.$snotify.error('Problem occurred');
      });

    },
    resetData(){
      this.examiner_lists=[];
      this.available_examiners=[];
      this.examiners=[];
      this.checkExaminer=false;
      this.examinerCheckError=0;
      this.examinerDetail={};
      this.examinerSearch='';
      this.availableExaminerSearch='';
    }
  },
  watch:{
    examinerSearch(val){
      let data={
        info:val,
        score_id:this.exam_day.score_id,
        exam_day_score_id: this.exam_day.score_id
      }
      examiner
          .getByExaminingScores(data)
          .then((response) => {
            this.examiners=response.data.data;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    },
    availableExaminerSearch(val)
    {
      let data={
        info:val,
        score_id:this.exam_day.score_id,
        date:'',
      }
      examiner
          .search(data)
          .then((response) => {
            this.available_examiners=response.data.data;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    }
  },
}
</script>